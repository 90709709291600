<template>
  <div class="homeView autoHeight_father">
    <div class="autoHeight_son" style="padding:10px 10px 150px 10px;">
      <div class="all_order mt0-auto-w98" @click="jumpTo('/introduce')" style="background: none;">
        <img style="width: 100%;border-radius: 0.4rem;" src="../assets/image/banner.png" alt="">
      </div>

      <!---------------------------------------------检索数据库范围部分 -------------------------------------------------------->
      <!--/////////////////// 检索数据库范围 ////////////////////////-->
      <van-notice-bar class="mt10 scrollBar" color="#1989fa" style="border-radius: 6px;background:#D9F3FF;">
        <span>检索数据库范围</span>
      </van-notice-bar>
      <div class="all_order mt10 box_top" style="width: 100%;margin-right: 0.6rem;">
        <van-cell class="text-left">
          <template #title>
            <van-icon name="medal" size="20" color="#1989FA" />
            <span class="pl5 custom-title fw">查收录</span>
          </template>
        </van-cell>
        <van-cell title="" class="dbScope_quote impact">
          <template #label>
            <van-row class="flex-align-items ml10">
              <van-checkbox-group v-model="checkedEmploy" @change="changeEmploy" direction="horizontal" shape="square" style="width: 100%;">
                <van-checkbox name="SCI1">SCI</van-checkbox>
                <van-checkbox name="EI1">EI</van-checkbox>
                <van-checkbox name="CPCI-S1">CPCI-S</van-checkbox>
              </van-checkbox-group>
              <van-checkbox-group v-model="checkedEmploy" direction="horizontal" shape="square" style="width: 100%;">
                <van-checkbox name="A&HCI1" class="mt10">A&HCI</van-checkbox>
                <van-checkbox name="SSCI1" class="mt10">SSCI</van-checkbox>
                <van-checkbox name="CPCI-SSH1" class="mt10">CPCI-SSH</van-checkbox>
              </van-checkbox-group>
              <van-checkbox-group v-model="checkedEmploy" direction="horizontal" shape="square" style="width: 100%;">
                <van-checkbox name="Scopus1" class="mt10">Scopus</van-checkbox>
                <van-checkbox name="CSCD1" class="mt10">CSCD</van-checkbox>
                <van-checkbox name="CNKI1" class="mt10">CNKI</van-checkbox>
              </van-checkbox-group>
            </van-row>
            <van-checkbox-group class="mt10" v-model="checkedEmploy" direction="horizontal" shape="square">
              <van-checkbox name="CSSCI1" class="ml10" style="flex:1">CSSCI</van-checkbox>
              <div @click="employShow=true" v-if="!employShow" style="width: 33.3%" class="text-left">其他数据库<van-icon name="arrow" /></div>
            </van-checkbox-group>


            <div class="mt15 flex-space-between ml10" v-if="employShow">
              <div class="flex-align-items" style="flex-wrap: wrap;width: 100%;">
                <label>其他：</label>
                <input-view
                    v-for="(item,index) in employArr"
                    :key="index"
                    v-model="item.name"
                    :class="['mr10',item.type===1&&index>1?'mt5':'mt5',(index%2===0&&index!==0)?'ml1_04rem':'']"
                    @clear="deleteInput('employ',index)"
                />
                <van-icon name="add" size="30" class="addInputBox_employ mt5" color="#bbb" @click="addInput('employ')"/>
              </div>
            </div>
          </template>
        </van-cell>
      </div>

      <!--/////////////////// 查引用 ////////////////////////-->
      <div class="all_order mt10" style="width: 100%;">
        <van-cell class="text-left">
          <template #title>
            <van-icon name="medal" size="20" color="#1989FA" />
            <span class="pl5 custom-title fw">查引用</span>
          </template>
        </van-cell>
        <van-cell title="" class="dbScope_quote impact">
          <template #label>
            <van-row class="flex-align-items ml10" >
              <van-checkbox-group v-model="checkedQuote" @change="changeQuote" direction="horizontal" shape="square" style="width: 100%;">
                <van-checkbox name="SCI2">SCI</van-checkbox>
                <van-checkbox name="CPCI-S2">CPCI-S</van-checkbox>
                <van-checkbox name="CPCI-SSH2">CPCI-SSH</van-checkbox>
              </van-checkbox-group>
              <van-checkbox-group v-model="checkedQuote" direction="horizontal" shape="square" style="width: 100%;">
                <van-checkbox name="SSCI2" class="mt10" >SSCI</van-checkbox>
                <van-checkbox name="A&HCI2" class="mt10" >A&HCI</van-checkbox>
                <van-checkbox name="Scopus2" class="mt10" >Scopus</van-checkbox>
              </van-checkbox-group>
              <van-checkbox-group v-model="checkedQuote" direction="horizontal" shape="square" style="width: 100%;">
                <van-checkbox name="CSCD2" class="mt10">CSCD</van-checkbox>
                <van-checkbox name="CNKI2" class="mt10">CNKI</van-checkbox>
                <div @click="quoteShow=true" v-if="!quoteShow" class="mt10">其他数据库<van-icon name="arrow" /></div>
              </van-checkbox-group>
            </van-row>

            <div class="mt15 flex-space-between ml10" v-if="quoteShow">
              <div class="flex-align-items" style="flex-wrap: wrap;width: 100%;">
                <label>其他：</label>
                <input-view
                    v-for="(item,index) in quoteArr"
                    :key="index"
                    v-model="item.name"
                    :class="['mr10 mt5',(index%2===0&&index!==0)?'ml1_04rem':'']"
                    @clear="deleteInput('quote',index)"
                />
                <van-icon name="add" size="30" class="addInputBox_quote mt5" color="#bbb" @click="addInput('quote')" />
              </div>
            </div>
          </template>
        </van-cell>

        <!--/////////////////// 排自引（他引） ////////////////////////-->
        <van-cell class="mt10 text-left">
          <template #title>
            <van-icon name="medal" size="20" color="#1989FA" />
            <span class="pl5 custom-title fw">排自引（他引）</span>
          </template>
          <template #label>
            <div class="mt10 flex-space-between ml10">
              <div class="flex-align-items" style="flex-wrap: wrap;width: 100%;">
                <label class="impact mt5" style="display: inline-block;width:0.74rem;">排除作者</label>
                <label class="impact fw" style="margin-right: 0.22rem">:</label>
                <input-view
                    v-for="(item,index) in heCitedArr"
                    :key="index"
                    v-model="item.name"
                    :class="['mr10 mt5',(index%2===0&&index!==0)?'ml1_08rem':'']"
                    @clear="deleteInput('hecited',index)"
                />
                <van-icon name="add" size="30" class="addInputBox_hecited mt5" color="#bbb" @click="addInput('hecited')" />
              </div>
            </div>
            <div style="width: 76%;padding: 10px 60px;">输入中文姓名或非拼音形式的外文名即可，无需提供各种拼音形式的英文名称</div>
          </template>
        </van-cell>
      </div>

      <!--/////////////////// 查期刊评价 ////////////////////////-->
      <div class="all_order mt10" style="width: 100%;">
        <van-cell class="text-left">
          <template #title>
            <van-icon name="medal" size="20" color="#1989FA" />
            <span class="pl5 custom-title fw fz16">期刊指标</span>
          </template>
        </van-cell>
        <van-cell title="" class="impact">
          <template #label>
            <!-- SCI期刊影响因子 -->
            <div class="fw text-left black">SCI期刊影响因子</div>
            <van-checkbox-group class="mt10 ml10" v-model="checkedImpact" @change="impactChange" direction="horizontal" shape="square">
              <van-checkbox name="最新版本" style="width: 3.2rem">最新版本</van-checkbox>
              <van-checkbox name="论文发表当年版本" >论文发表当年版本</van-checkbox>
            </van-checkbox-group>

            <!-- 科睿唯安JCR分区 -->
            <div class="fw mt20 text-left black">科睿唯安JCR分区</div>
            <van-checkbox-group class="mt10 ml10" v-model="checkedJCR" @change="JCRChange" direction="horizontal" shape="square">
              <van-checkbox name="最新版本" style="width: 3.2rem" >最新版本</van-checkbox>
              <van-checkbox name="论文发表当年版本" >论文发表当年版本</van-checkbox>
            </van-checkbox-group>

            <!-- 中科院JCR分区 -->
            <van-checkbox class="mt10" v-model="bigJcr" shape="square" v-show="false">学科大类</van-checkbox>
            <div class="fw mt20 text-left black">中科院JCR分区</div>
            <van-checkbox-group class="mt10 ml10" v-model="checkedZky" direction="horizontal" shape="square">
              <van-checkbox name="最新升级版" style="width: 3.2rem">最新升级版</van-checkbox>
              <van-checkbox name="论文发表当年升级版" >论文发表当年升级版</van-checkbox>
            </van-checkbox-group>
            <van-checkbox-group class="mt10 ml10" v-model="checkedZky" direction="horizontal" shape="square">
              <van-checkbox name="论文发表当年基础版" >论文发表当年基础版</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-cell>
      </div>

      <!-------------------------------------------需要检索的论文部分 ---------------------------------------------------------->
      <van-notice-bar class="mt10" color="#1989fa" style="border-radius:6px;background:#D9F3FF;">
        <span class="red fw pr5">*</span>需要检索的论文
      </van-notice-bar>
      <div class="all_order mt10" style="padding: 0.4rem">
        <div class="text-left fz16 ml5">每行一条文献（换行），当前文献数: <span class="red">{{ prperCount }}</span> 条</div>
        <textarea class="mt10 literature_textArea retrieved_papers" v-model="retrieved_papers" ref="retrievedRef"  placeholder="不同文献之间请换行" />
        <div class="text-left mt5" style="color: #969799">特殊需求请填写在下方“备注”内，请勿在文献中填写额外需求</div>
      </div>


      <!--------------------------------------------收件信息部分 -------------------------------------------------------------->
      <van-notice-bar class="mt10" color="#1989fa" style="border-radius:6px;background:#D9F3FF;">
        <span class="red fw pr5">*</span>报告信息
      </van-notice-bar>
      <div class="all_order mt10 homeView" style="padding: 10px">
        <van-form>
          <!--委托人-->
          <van-cell-group inset>
            <van-field
                ref="assignorRef"
                scroll-to-error
                v-model="personInformation.assignor"
                type="text"
                name="委托人"
                label="委&nbsp;&nbsp;&nbsp;托&nbsp;&nbsp;人"
                placeholder="单位委托请填写单位名称"
            />

            <!--邮箱-->
            <van-popover
                style="width: 100%;"
                v-model:show="showPopover"
                :actions="inputEmailString"
                actions-direction="vertical"
                @select="setEmailInput"
            >
              <template #reference>
                <van-field
                    ref="emailRef"
                    scroll-to-error
                    v-model="personInformation.inputEmail"
                    type="email"
                    name="validatorEmail"
                    label="邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱"
                    :rules="[{validator: validatorEmail}]"
                    placeholder="电子版报告将发送至该邮箱"
                />
              </template>
            </van-popover>

            <!--是否需要纸质报告-->
            <van-field name="radio" label="是否需要纸质报告" label-width="60">
              <template #input>
                <van-radio-group class="ml15" v-model="personInformation.isNeedReport" @change="changeReportNum" direction="horizontal">
                  <van-space size="20px">
                    <van-radio name="true">是</van-radio>
                    <van-radio name="false">否</van-radio>
                  </van-space>
                </van-radio-group>
              </template>
            </van-field>


            <!--报告份数-->
            <van-field name="stepper" label="报告份数">
              <template #input>
                <van-stepper v-model="personInformation.reportNum" :disabled="personInformation.isNeedReport==='false'" :min="personInformation.isNeedReport==='false'?0:1" />
              </template>
            </van-field>
            <!--/////////////////// 收件信息 //////////////////////-->
            <div v-if="!!personInformation.reportNum">
              <div class="mt15 text-left">
                <van-icon name="medal" color="#1989FA" size="20" />
                <span class="fw pl5 fz16">收件地址</span>
              </div>

              <!--自动识别收件信息-->
              <div class="mt10">
                <div class="text-left pb10" style="color: #fff;background: lightskyblue;padding:4px 10px;border-radius: 0.4rem 0.4rem 0 0;">示例:王小五，136xxx0000，重庆市渝北区洪湖路某小区</div>

                <div style="position: relative;top: -12px;">
                  <textarea class="literature_textArea" placeholder="请粘贴或输入文本，点击“识别”自动识别姓名、电话和地址" v-model="keyText" />
                  <div style="position: absolute;bottom: 10px;right: 20px;">
                    <van-button v-if="keyText" type="default" size="small" round @click="clearText">&nbsp;&nbsp;&nbsp;&nbsp;清空&nbsp;&nbsp;&nbsp;&nbsp;</van-button>
                    <van-button class="ml5" type="primary" size="small" round @click="identify(keyText)" :disabled="keyText.length<=0">&nbsp;&nbsp;&nbsp;&nbsp;识别&nbsp;&nbsp;&nbsp;&nbsp;</van-button>
                  </div>
                </div>
              </div>

              <!--收件人姓名-->
              <van-field
                  ref="nameRef"
                  scroll-to-error
                  type="text"
                  name="填写收件人姓名"
                  label="收&nbsp;&nbsp;件&nbsp;&nbsp;人"
                  v-model="personInformation.receiveName"
                  placeholder="填写收件人姓名"
              />

              <!--收件人手机号-->
              <van-field
                  ref="phoneRef"
                  scroll-to-error
                  type="tel"
                  name="validatorMessage"
                  label="联系电话"
                  v-model="personInformation.receivePhone"
                  placeholder="填写收件人手机号"
                  :rules="[{ validator: validatorMessage }]"
              />
              <!--地区选择-->
              <van-field
                  ref="areaRef"
                  scroll-to-error
                  type="text"
                  name="地区选择"
                  label="地区选择"
                  v-model="personInformation.receiveArea"
                  is-link
                  readonly
                  placeholder="点击选择省市区"
                  @click="showArea = true"
              />
              <van-popup v-model:show="showArea" position="bottom">
                <van-area
                    :area-list="areaList"
                    @confirm="onConfirm"
                    @cancel="showArea = false"
                />
              </van-popup>

              <!--详细地址-->
              <van-field
                  ref="addressRef"
                  scroll-to-error
                  type="text"
                  name="详细地址"
                  label="详细地址"
                  v-model="personInformation.receiveAddress"
                  placeholder="输入小区/写字楼"
              />
            </div>
          </van-cell-group>
        </van-form>
      </div>
      <!-------------------------------------------备注（可选）的论文部分 ------------------------------------------------------->
      <van-notice-bar class="mt10" color="#1989fa" style="border-radius: 6px;background:#D9F3FF;">
        <span>备注（可选）</span>
      </van-notice-bar>
      <div class="all_order mt10" style="padding: 15px">
        <textarea class="literature_textArea" v-model="remark_order" placeholder="若您有特殊检索需求，可在此填写。如:自引、他引均做展示" style="height:1.5rem;" />
      </div>
    </div>

    <div class="bottom_Btn flex-space-center" :style="['padding:15px','width: calc(100% - 30px)',isBottom?'bottom:60px':'bottom:78px']">
      <van-button
          block
          round
          type="primary"
          class="ml5 mr5"
          style="width:calc(100% - 0.4rem)"
          v-if="checkedEmploy.length||checkedQuote.length"
          @click="commitOrder" :loading="commitLoading">提交订单
      </van-button>
      <a href="#boxTop" class="ml5 mr5" style="width:calc(100% - 0.4rem)" v-else>
        <van-button block round type="primary" @click="commitOrder" :loading="commitLoading">
          提交订单
        </van-button>
      </a>
    </div>
  </div>

  <!-- 如果用户没有登录，则底部弹出登录 -->
  <van-popup
      v-model:show="showBottom"
      class="mt-auto"
      closeable
      round
      position="bottom"
      :style="{ height: '55%' }"
  >
    <login-component :msg="orderId" />
  </van-popup>

  <validate-component v-if="goValidate" />

<!--  <van-overlay :show="newYearNotice" @click="newYearNotice = false" class="flex-space-center">-->
<!--    <div class="wrapper" @click.stop>-->
<!--      <div class="flex-space-center">-->
<!--        <div class="pb10 pt5" style="background: #fff;width: calc(100% - 2rem);border-radius: 0.2rem;">-->
<!--          <h1>温馨提示</h1>-->
<!--          <div style="padding: 10px 20px;font-size: 16px;text-align: left;line-height: 30px;">-->
<!--            <div>尊敬的客户：</div>-->
<!--            <div style="text-indent: 34px;">您好！春节假期期间（1月25日-2月6日）出具的报告，纸质报告统一于2月7日寄出。</div>-->
<!--          </div>-->
<!--          <div class="mt20 fz16" @click="newYearNotice = false" style="color: dodgerblue;">我知道了</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </van-overlay>-->
  <router-view/>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
//引入中国省市区数据
import {areaList} from '@vant/area-data';
import {showConfirmDialog, showFailToast, showToast} from "vant";
import $ from "jquery";
import LoginComponent from "@/components/loginComponent.vue";  //引入登录页面组件
import {getTemp, identifyAddress, saveList, submitList} from "@/api/home";
import {jumpTo} from "@/api/routerJS";
import {useRoute, useRouter} from "vue-router";
import {getDeatil} from "@/api/orderDetail";
import InputView from "@/components/inputView.vue";
import {useStore} from "vuex";


const route=useRoute();
const store=useStore();
const isIOS=ref(false);  //是否为苹果
//判断手机设备
const userAgent = window.navigator.userAgent;
if (userAgent.indexOf("Android") > -1) {
  isIOS.value=false;
} else if (userAgent.indexOf("iPhone") > -1) {
  isIOS.value=true;
}
const isBottom = ref(false);
if(route.path==='/'&& store.state.phoneClass===false&&isIOS.value){
  isBottom.value = true;
}


const showPopover = ref(false);
let isLogin;
let wxUser = localStorage.getItem("wxCscyUser");
if(wxUser){
  isLogin=wxUser&&JSON.parse(wxUser).phone;   //构造用户是否绑定手机标识
}

/**
 * 地址识别 调阿里巴巴接口
 * @param text
 */
const identify=(text)=>{
  if(text){
    identifyAddress(text).then((res)=>{
      if(res.success){
        personInformation.value.receiveName=res.name;
        personInformation.value.receivePhone=res.phone;
        let adminDivision=res.adminDivision.split(';');
        let firstStr = adminDivision[0];
        if (firstStr && (firstStr==='重庆'||firstStr==='上海'||firstStr==='北京'||firstStr==='天津')){
          firstStr +='市';
        }
        if(adminDivision.length===1&&firstStr){
          personInformation.value.receiveArea=firstStr;
        }
        if(adminDivision.length===2&&firstStr&&adminDivision[1]){
          personInformation.value.receiveArea=firstStr+'/'+adminDivision[1];
        }
        if(adminDivision.length>=3&&firstStr&&adminDivision[1]&&adminDivision[2]){
          personInformation.value.receiveArea=firstStr+'/'+adminDivision[1]+'/'+adminDivision[2];
        }
        let string;
        if (firstStr==='重庆市'||firstStr==='上海市'||firstStr==='北京市'||firstStr==='天津市'){
          //判断市区出现的次数
          let addressLen = countMunicipalityOccurrences(res.address);
          if (addressLen === 1) {
            string = adminDivision[1] + adminDivision[2];
          } else {
            string = adminDivision[0] + adminDivision[1] + adminDivision[2];
          }
        }else {
          string=firstStr+adminDivision[1]+adminDivision[2];
        }
        personInformation.value.receiveAddress=res.address.replace(string,'')
      }
    })
  }
}

/**
 * 得出直辖市出现的次数
 */
const countMunicipalityOccurrences = (str) => {
// 定义直辖市名称数组
  const municipalities = ["北京", "天津", "上海", "重庆"];

  // 存储出现次数的对象
  const occurrences = {};

  // 遍历直��市名称
  municipalities.forEach(municipality => {
    // 使用正则表达式检查每个直辖市名称的出现次数
    const regex = new RegExp(municipality, 'g');
    const matches = str.match(regex);
    occurrences[municipality] = matches ? matches.length : 0;
  });

  // 返回出现次数的最大值
  return Math.max(...Object.values(occurrences));
}


const clearText=()=>{
  keyText.value='';
}

const employShow=ref(false);//收录输入框显示与隐藏
const quoteShow=ref(false);//引用输入框显示与隐藏
onMounted(() => {
  $('.van-field__right-icon').hide();

  //  定时任务
  tempSaveTask();
  if (wxUser) {
    //再次委托，页面有传值
    if (route.query.param) {
      getDeatil(Number(route.query.param)).then((res) => {
        if (res.data) {
          deelData(res.data);
          if (res.data.id) {
            orderId.value = res.data.id;
          }
          wechatShare();
        }
      })
    } else {
      //不是再次委托
      //已进入页面查询是否有临时订单
      getTemp().then(res => {
        /**回显到填写页面*/
        if (res.data) {
          deelData(res.data);
          if (res.data.id) {
            orderId.value = res.data.id;
          }
          wechatShare();
        }
      })
    }
  }
})

/**
 * 页面销毁时，清除定时器
 */
onUnmounted(() => {
  clearInterval(timer);
});

//跳转页面时调用保存事件
// onBeforeUnmount(()=>{
//   getApplyFormData();
//   saveList(applyForm.value);
// });

const router_new=useRouter();
watch(
    ()=>router_new.currentRoute.value,
    ()=>{
      let path=router_new.currentRoute.value;
      if(path.path!=="/"&&path.path!=="/complete"){
        getApplyFormData();
        saveList(applyForm.value)
      }else{
        applyForm.value=null;
      }
    }
)


/**
 * 订单信息，返回给后端
 * @type {Ref<UnwrapRef<{}>>}
 */
const applyForm=ref({});


/**
 * 每次清空定时器
 */
let timer;

/**
 * 回显数据处理数据
 * @param data：后端返回的数据
 */
const deelData=(data)=>{
  if(data){
    //回显收录、引用（checkbox），排除他引
    let strings = data.dbScope.split(";");
    /*      let ty = "", yy='',/!* sl='',*!/ sl_input='';*/
    let sldb=['SCI1','EI1','CPCI-S1','A&HCI1','SSCI1','CPCI-SSH1','Scopus1','CSCD1','CNKI1','CSSCI1'];
    let yydb=['SCI2','CPCI-S2','A&HCI2','SSCI2','CPCI-SSH2','Scopus2','CSCD2','CNKI2'];
    let tempInputSl = [],tempInputYy = [], tempInputTy = [];
    //回显收录、引用、他引
    strings.forEach((s)=>{
      if (s){
        //收录
        if (s.trim().endsWith("1")) {
          //收录checkbox框
          if (sldb.indexOf(s)!==-1){
            checkedEmploy.value.push(s.trim())
          }else {
            //收录input框
            tempInputSl.push({'name': s.replace("1",""),'type':0})
          }
        }
        //引用
        if (s.trim().endsWith("2")) {
          //引用checkbox框
          if (yydb.indexOf(s)!==-1){
            checkedQuote.value.push(s.trim())
          }else{
            //引用input框
            tempInputYy.push({'name': s.replace("2",""),'type':0})
          }
        }
        //他引
        if (s.trim().endsWith("3")) {
          tempInputTy.push({'name': s.trim().replace('3',''),'type':0})
        }
      }
    })
    // 如果回显的输入框有数据，则赋值，若没有，则保持初始值
    if (tempInputSl.length){   //收录
      employArr.value = tempInputSl;
    }
    if (tempInputYy.length){  //引用
      quoteArr.value = tempInputYy;
    }
    if (tempInputTy.length){   //他引
      heCitedArr.value = tempInputTy;
    }
    //影响因子
    if(data.impactFactor){
      checkedImpact.value.push(data.impactFactor);
    }
    //科睿唯安分区
    if(data.claricateJCR){
      checkedJCR.value.push(data.claricateJCR);
    }
    //中科院分区
    if(data.casJCR){
      checkedZky.value=data.casJCR.split(';');
    }
    //备注回显remark
    if(data.remark){
      remark_order.value=data.remark;
    }
    //需要检索文献回显
    if(data.noveltyPaper){
      retrieved_papers.value=data.noveltyPaper;
    }
    //委托人（单位）
    if(data.projectMandator){
      personInformation.value.assignor=data.projectMandator;
    }
    //邮箱
    if(data.contactPersonEmail){
      personInformation.value.inputEmail=data.contactPersonEmail;
    }
    //报告份数
    if(data.reportNum){
      personInformation.value.reportNum=data.reportNum;
    }
    //是否需要报告
    if (data.reportNum>0){
      personInformation.value.isNeedReport='true';
    }
    //收件人
    if(data.contactPerson){
      personInformation.value.receiveName=data.contactPerson;
    }
    //电话
    if(data.contactPersonPhone){
      personInformation.value.receivePhone=data.contactPersonPhone;
    }
    //通讯地址 省/市/区县
    if(data.province&&data.city&&data.district){
      personInformation.value.receiveArea=data.province+'/'+data.city+'/'+data.district;
    }
    //详细地址
    if(data.address){
      personInformation.value.receiveAddress=data.address;
    }
    employArr.value.forEach((item)=>{
      if(item.name){
        employShow.value=true;
      }
    })
    quoteArr.value.forEach((item)=>{
      if(item.name){
        quoteShow.value=true;
      }
    })
  }
}

const keyText=ref('');  //识别的文字内容
//保存时处理数据
const getApplyFormData=()=>{
  let ApplyForm = {};
  // 委托单位/委托人
  if (personInformation.value.assignor) {
    ApplyForm.projectMandator = personInformation.value.assignor ;
  }
  //项目名称
  if (personInformation.value.assignor) {
    let employ_new =[],quote_new=[];
    //把收录的input框的数据库单独筛选出来
    employArr.value.filter((item)=>{
      if (item.name){
        employ_new.push(item.name);
      }
    })
    //把引用的input框的数据库单独筛选出来
    quoteArr.value.filter((item)=>{
      if (item.name){
        quote_new.push(item.name);
      }
    })
    let str;
    //获取收录：checkbox和input框全部引用数据库
    let allEmployArr =[...new Set([...checkedEmploy.value,...employ_new])];
    //获取引用：checkbox和input框全部引用数据库
    let allQuoteArr =[...new Set([...checkedQuote.value,...quote_new])];
    //如果只有收录数据库，没有引用数据库，那么项目名称则为：*****发表的论文收录情况
    if(allEmployArr.length&&!allQuoteArr.length){
      str='发表的论文收录情况';
    }else if(allQuoteArr.length&&!allEmployArr.length){
      //如果只有引用数据库，没有收录数据库，那么项目名称则为：*****发表的论文引用情况
      str='发表的论文引用情况';
    }else{
      //如果既有引用数据库，又有收录数据库，那么项目名称则为：*****发表的论文收录引用情况
      str='发表的论文收录引用情况';
    }
    ApplyForm.projectName = personInformation.value.assignor + str;
  }
  //通讯地址
  ApplyForm.province = personInformation.value.receiveArea.split('/')[0];  //省
  ApplyForm.city = personInformation.value.receiveArea.split('/')[1];     //市
  ApplyForm.district = personInformation.value.receiveArea.split('/')[2];  //区县
  //详细地址
  ApplyForm.address = personInformation.value.receiveAddress;
  //收件人
  ApplyForm.contactPerson = personInformation.value.receiveName;
  //电话
  ApplyForm.contactPersonPhone = personInformation.value.receivePhone;
  //邮箱
  ApplyForm.contactPersonEmail = personInformation.value.inputEmail;
  //报告份数
  ApplyForm.reportNum = personInformation.value.reportNum;
  //需要检索的论文
  ApplyForm.noveltyPaper = retrieved_papers.value;

  console.log(ApplyForm,'vvvvvvvvvvvvvvvvvvvvvvvvvvvv')
  //数据库检索范围
  //处理收录数据  checkbox + input
  var shouluArr = [];
  let inputArr=[];
  employArr.value.forEach((item)=>{
    if(item.name){
      inputArr.push(item.name+'1');
    }
  })
  shouluArr=Array.from(new Set(checkedEmploy.value.concat(inputArr)));

  //处理引用数据  checkbox + input
  var yyArr = [];
  let inputYYArr=[];
  quoteArr.value.forEach((item) => {
    if (item.name) {
      inputYYArr.push(item.name+'2');
    }
  })
  yyArr=Array.from(new Set(checkedQuote.value.concat(inputYYArr)));

  //处理他引数据  checkbox + input
  var tayinArr = [];
  let inputTYArr=[];
  heCitedArr.value.forEach((item) => {
    if (item.name) {
      inputTYArr.push(item.name+'3');
    }
  })
  tayinArr=Array.from(new Set(checkedHecited.value.concat(inputTYArr)));
  let addAll = [...shouluArr, ...yyArr, ...tayinArr];
  ApplyForm.dbScope = addAll.join(';');

  // SCI（期刊影响因子）
  ApplyForm.impactFactor = checkedImpact.value.join(';');
  //中科院JCR分区（默认为基础版）
  if(checkedZky.value){
    ApplyForm.casJCR = checkedZky.value.join(';');
  }
  //科睿唯安JCR分区
  if(checkedJCR.value){
    ApplyForm.claricateJCR = checkedJCR.value.join(';');
  }
  //备注
  if(remark_order.value){
    ApplyForm.remark = remark_order.value;
  }
  //全局变量，ApplyForm赋值给applyForm
  applyForm.value = ApplyForm;
}

const resetApplyForm=()=>{
  checkedEmploy.value=[];
  employArr.value=[{name:'',type:0},{name:'',type:0}];
  checkedQuote.value=[];
  quoteArr.value=[{name:'',type:0},{name:'',type:0}];
  heCitedArr.value=[{name:'',type:0},{name:'',type:0}];
  checkedImpact.value=[];
  checkedZky.value=[];
  checkedJCR.value=[];
  retrieved_papers.value='';
  remark_order.value='';
  personInformation.value={
    assignor:'',
    inputEmail:'',
    isNeedReport:'false',
    reportNum:0,
    receiveName:'' ,
    receivePhone:'',
    receiveAddress:'' ,
    receiveArea:''  ,
  }
}

//是否执行定时器
const setInterValTrue = ref(true);
/**
 * 定时保存 每隔5秒保存一次
 */
const tempSaveTask=()=>{
  timer = setInterval(()=>{
    getApplyFormData();
    /**
     * 调用saveList保存事件
     */
    if (setInterValTrue.value){
      if(applyForm.value.dbScope){
        saveList(applyForm.value);
      }
    }
  },20000)
}

/**
 * 收录 数据库
 * @type {Ref<UnwrapRef<*[]>>}
 */
const checkedEmploy=ref([]);
const changeEmploy=(checkValue)=>{
  checkedEmploy.value=checkValue;
}


/**
 * 引用  数据库
 * @type {Ref<UnwrapRef<*[]>>}
 */
const checkedQuote=ref([]);
const changeQuote=(checkValue)=>{
  checkedQuote.value=checkValue;
}


/**
 * input框添加收录、引用（其他）数据库、他引
 */
const employArr=ref([{name:'',type:0},{name:'',type:0}]);  //收录
const quoteArr=ref([{name:'',type:0},{name:'',type:0}]);  //引用
const heCitedArr=ref([{name:'',type:0},{name:'',type:0}]);  //他引


/**
 * 他引（自引）
 */
const checkedHecited=ref([]);



/**
 * 点击添加icon，新增input框
 * @param type :收录、引用、他引区分
 */
const addInput=(type)=>{
  if(type==='employ'){
    employArr.value.push({name:'',type:1});
    $('.addInputBox_'+type).css('marginTop','10px');
  }else if(type==='quote'){
    quoteArr.value.push({name:'',type:1});
    $('.addInputBox_'+type).css('marginTop','10px');
  }else if(type==='hecited'){
    heCitedArr.value.push({name:'',type:1});
    $('.addInputBox_'+type).css('marginTop','0.2rem');
  }
  getApplyFormData();
}


/**
 * 删除输入框  收录、引用、他引
 * @param type :收录、引用、他引
 * @param deleteIndex 删除的索引值
 */
const deleteInput=(type,deleteIndex)=>{
  if(type==='employ'){
    employArr.value.splice(deleteIndex,1);
  } else if(type==='quote'){
    quoteArr.value.splice(deleteIndex,1);
  }else if(type==='hecited'){
    heCitedArr.value.splice(deleteIndex,1);
  }
  getApplyFormData(applyForm.value)
}


/**
 * 备注（可选）
 * @type {Ref<UnwrapRef<string>>}
 */
const remark_order=ref('');


/**
 * 需要检索的论文
 * @type {Ref<UnwrapRef<string>>}
 */
const retrieved_papers=ref('');
const prperCount=ref(0);


/**
 * 监听检索文献的输入情况
 */
watch((retrieved_papers),(value)=>{
  if(value){
    if(value.indexOf('\n')>-1){
      let arr=value.split('\n');
      arr = arr.filter(item => item !== '' && item !== null && item !== undefined && !Number.isNaN(item));
      prperCount.value=arr.length;
    }else{
      prperCount.value=1;
    }
  }else{
    prperCount.value=0
  }

},{
  immediate:true,
  deep:true
})


/**
 * 影响因子数据库勾选情况，有且只能勾选一个
 * @type {Ref<UnwrapRef<string>>}
 */
const checkedImpact=ref([]);
const impactChange=(value)=>{
  if(value.length>1){
    checkedImpact.value=[];
    showToast('SCI（期刊影响因子）有且只能选择其中一项！')
  }
}


/**
 * 科睿唯安数据库勾选情况，有且只能勾选一个
 * @type {Ref<UnwrapRef<string>>}
 */
const checkedJCR=ref([]);
const JCRChange=(value)=>{
  if(value.length>1){
    checkedJCR.value=[];
    showToast('科睿唯安JCR分区有且只能选择其中一项！')
  }
}


/**
 * 中科院JCR默认选中大类
 */
const bigJcr=ref(true);
//中科院数据库勾选情况，有且只能勾选一个
const checkedZky=ref();


/**
 * 用户收件及报告信息
 */
const personInformation=ref({
  assignor:'',  //委托人
  inputEmail:'',//绑定邮箱的输入值
  isNeedReport:'false',  //是否需要纸质报告
  reportNum:0,  //报告份数
  receiveName:'' ,   //收件人姓名
  receivePhone:'',  //收件人手机号
  receiveAddress:'' , //收件人详细地址
  receiveArea:''  , //收件人省地区
})

const newYearNotice = ref(false);
/**
 * 默认选中不需要纸质报告，通过change事件，改变reportNum
 * @param value
 */
const changeReportNum=(value)=>{
  if(value==='false'){
    personInformation.value.reportNum=0;
  }
  if(value==='true'){
    newYearNotice.value =  true ;
  }
}

/**
 * 校验手机号码格式
 * @param val
 * @returns {string|boolean}
 */
const validatorMessage = () => {
  let val=personInformation.value.receivePhone;
  if(/^1[3456789]\d{9}$/.test(val)){
    return true
  }else{
    return `格式不正确，请重新输入！`;
  }
};

/**
 * 校验邮箱格式
 * @returns {string|boolean}
 */
const validatorEmail=()=>{
  let val=personInformation.value.inputEmail;
  var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
  if (reg.test(val)) {
    return true;
  } else {
    return `邮箱格式不正确，请重新输入！`;
  }
}


/**
 * 地址区域选择
 * @type {Ref<UnwrapRef<boolean>>}
 */
let showArea = ref(false);
const onConfirm = ({ selectedOptions }) => {
  showArea.value = false;
  personInformation.value.receiveArea= selectedOptions.map((item) => item.text).join('/');
};


/**
 * 点击获取选中的值，然后赋值给inputEmail，从而改变inputEmail的值
 * @param email
 */
const setEmailInput=(email)=>{
  personInformation.value.inputEmail=email.text;
}


/**
 * 计算属性，自动补全邮箱后缀
 * @type {ComputedRef<unknown>}
 */
const inputEmailString=computed(()=>{
  if (! personInformation.value.inputEmail) return [];
  if ( personInformation.value.inputEmail.indexOf('@') > -1) return [];
  return [
    {'text':personInformation.value.inputEmail + '@qq.com'},
    {'text':personInformation.value.inputEmail + '@163.com'},
    {'text':personInformation.value.inputEmail + '@126.com'},
    {'text':personInformation.value.inputEmail + '@yahoo.com'},
    {'text':personInformation.value.inputEmail + '@gmail.com'},
    {'text':personInformation.value.inputEmail + '@msn.com'},
  ]
})

/**
 * 若用户没有绑定手机号码，则弹出绑定弹窗
 * @type {Ref<UnwrapRef<boolean>>}
 */
const showBottom = ref(false);
const goValidate = ref(false);

// 提交钱校验必填数据有没有填写
const nameRef=ref(null);
const phoneRef=ref(null);
const addressRef=ref(null);
const areaRef=ref(null);
const emailRef=ref(null);
const assignorRef=ref(null);
const retrievedRef=ref(null);
const verifyDta=()=>{
  $('.box_top').removeAttr('id','boxTop')
  if(!retrieved_papers.value){
    showToast('检索的论文不能为空！')
    retrievedRef.value.focus();
    return false;
  }
  if(!personInformation.value.assignor){
    showToast('委托单位不能为空！')
    assignorRef.value.focus();
    return false;
  }
  if(!personInformation.value.inputEmail){
    showToast('邮箱不能为空！')
    emailRef.value.focus();
    return false;
  }
  if(validatorEmail()==='邮箱格式不正确，请重新输入！'){
    emailRef.value.focus();
    showFailToast('邮箱格式不正确，请重新输入！')
    return false;
  }
  if(personInformation.value.reportNum){
    if(!personInformation.value.receiveName){
      showToast('收件人不能为空！')
      nameRef.value.focus();
      return false;
    }
    if(!personInformation.value.receivePhone){
      showToast('手机号不能为空！')
      phoneRef.value.focus();
      return false;
    }
    if(validatorMessage()==='格式不正确，请重新输入！'){
      phoneRef.value.focus();
      showFailToast('手机格式不正确，请重新输入！')
      return false;
    }
    if(!personInformation.value.receiveArea){
      showToast('地区不能为空！')
      areaRef.value.focus();
      return false;
    }
    if(!personInformation.value.receiveAddress){
      showToast('详细地址不能为空！')
      addressRef.value.focus();
      return false;
    }
  }
  return true;
}

/**
 * 提交订单事件
 */
const orderId=ref(0);
const commitLoading=ref(false);//提交按钮加载中状态
const commitOrder=()=>{
  setInterValTrue.value = false ;
  if(checkedEmploy.value.length||checkedQuote.value.length){
    //校验必填框有没有填写数据
    let b = verifyDta();
    if (b){
      let isAuthenticated = wxUser&&JSON.parse(wxUser).unionid;
      if (isAuthenticated){
        // 1、若用户已经绑定手机号码
        if(isLogin){
          showConfirmDialog({
            title: '确定填写无误并提交订单吗？',
          }).then(() => {
            commitLoading.value=true;
            // 先调用保存事件saveList得到订单id，id作为提交订单的参数
            getApplyFormData();
            saveList(applyForm.value).then(res =>{
              let id = res.data.id;
              //参数为 id  ===》先调用保存事件saveList得到订单id，id作为提交订单的参数
              submitList(id).then((res)=>{
                if (res.success){
                  resetApplyForm(); //提交成功后清空数据
                  jumpTo('/complete',id);
                }else{
                  showFailToast(res.msg)
                  commitLoading.value=false;
                  if (res.code==='Unbind'){
                    showBottom.value = true;
                    goValidate.value = false;
                    // isLogin=true;
                  }
                }
              }).catch(()=>{
                commitLoading.value=false;
              });
            });
          }).catch(()=>{
            showFailToast('取消')
          });
        }else{

          // 2、若用户没有绑定手机号码
          showBottom.value = true;
          goValidate.value = false;
          // isLogin=true;
        }
      } else{
        // 未授权,调起授权
        goValidate.value = true;
        showBottom.value = false;
        // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b7c22d24bb08527&redirect_uri=https://wechat.chaxin.org.cn/&response_type=code&scope=snsapi_userinfo&state=auto123#wechat_redirect&forcePopup=true'
      }
    }


  }else{
    showToast('请选择检索数据库范围！');
    $('.box_top').attr('id','boxTop');
    return false;
  }
}

import {authenticate} from "@/api/login";
import wxPay from "../api/wechat_JDK";
import wx from "weixin-js-sdk";
import ValidateComponent from "@/components/validateComponent.vue";


const params = new URLSearchParams(window.location.search);
let code = params.get('code');// 这是微信返回的code
console.log(code)
let state = params.get('state');// 登录成功后的参数
console.log(state)

if (code && state.indexOf("tabbar")!==-1) {
  authenticate(code,state).then(res =>{
    console.log(res)
    if (res.success){
      if (res.data) {
        // 更新本地用户信息
        localStorage.setItem("wxCscyUser",JSON.stringify(res.data));

        //  判断是否绑定了手机号
        if (!res.data.phone){
          // 2、若用户没有绑定手机号码
          showBottom.value = true;
          goValidate.value = false;
          // isLogin=true;
        }else{
          // 并且自动提交
          let id = res.applyId;
          let b = verifyDta();
          if(b){
            submitList(id).then((res)=>{
              if (res.success){
                resetApplyForm();
                isLogin=true;
                jumpTo('/complete',id);
              }else{
                showFailToast(res.msg)
                commitLoading.value=false;
                if (res.code==='Unbind'){
                  showBottom.value = true;
                  goValidate.value = false;
                }
              }
            }).catch(()=>{
              commitLoading.value=false;
            });
          }
        }
      }
      // 未绑定页面
      if (res.code ==='needBind'){
        // 绑定手机号
        showBottom.value = true;
        goValidate.value = false;
        // isLogin=true;
      }
    }
  })
}


const wechatShare =() =>{
  let href = window.location.href;
  wxPay.getWxPaySign({
    url: href
  }).then((res)=>{
    console.log(res)
    if (res.success) {
      console.log("初始化微信支付")
      let data = res.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      });
      wx.checkJsApi({
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function(res) {
          console.log(res)
        },
        fail:function(err){
          console.log(err)
        }
      });
      // 配置完成后返回一个resolve
      wx.ready(() => {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，
        // 则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        console.log("ready了")
        // 分享给朋友
        wx.updateAppMessageShareData({
          title: '查收查引-查新中心', // 分享标题
          desc: '当天出具报告，全流程在线，一级咨询单位', // 分享描述
          link: 'https://wechat.chaxin.org.cn/list', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://www.chaxin.org.cn/static/front/images/logo-new01.png', // 分享图标
          success: function () {
            // 设置成功
            console.log('分享成功！')
          }
        });
        // 分享到朋友圈

        wx.updateTimelineShareData({
          title: '查收查引-查新中心', // 分享标题
          desc: '当天出具报告，全流程在线，一级咨询单位', // 分享描述
          link: 'https://wechat.chaxin.org.cn/list', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://www.chaxin.org.cn/static/front/images/logo-new01.png', // 分享图标
          success: function () {
            // 设置成功
            console.log('分享成功！')
          }
        });
        wx.error((err) => {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log(err)
        })
      })
    }
  }).catch((err)=>{
    showFailToast(err.message)
  })
}

</script>


<style lang="scss" scoped>
.all_order{
  background: #fff;
  .impact,.impact div{
    font-size:0.35rem;
  }
  .literature_textArea{
    height: 3.4rem; border: 0.04rem solid #cccccc;padding: 0.2rem;width:calc(100% - 0.46rem);border-radius: 0.2rem;font-size:0.35rem!important;
  }
}
.dbScope_quote .van-checkbox{
  display: flex;align-items: center;width: 33%;
}
textarea::placeholder{
  font-size:0.35rem!important;
}
.hintBox {
  width: 100%;
  max-height:100px;
  overflow-y:scroll;
  overflow-x: auto;
  position: relative;
  z-index: 9999;
  background-color: #fff;
}
.hintItem {
  margin-top: 2px;
  cursor:pointer;
  font-size:0.4rem;
  color: #898989;
  padding: 5px 0;
}
.heCited_input{
  width: 25%;height:0.8rem;border:0.04rem solid #dcd9d9;text-align: center;
}
.bottom_Btn{
  background:linear-gradient(rgba(255,255,255,.2),#fff);position: absolute;
}
.ml1_04rem{margin-left: 1.06rem;}
</style>
